import * as React from 'react';
import {Helmet} from 'react-helmet';
import Header from '../components/header';
import Footer from '../components/footer';
import '../styles/main.less';
import '@fontsource/barlow-condensed';
import '@fontsource/asap-condensed';

// markup
const DatenschutzPage = () => {
  return (
      <div>
        <Helmet htmlAttributes={{lang: 'de'}}>
          <meta charSet="utf-8"/>
          <title>Datenschutz | Kinderosteopathie</title>
          <link rel="canonical" href="https://kinderosteopathie.bayern/datenschutz"/>
          <meta name="description"
                content="Datenschutz Hinweise für Osteopathie Praxis von Karin Eideloth in Utting am Ammersee "/>
          <meta name="keywords"
                content="Datenschutz, Praxis Karin Eideloth, Utting, Ammersee, München, Landsberg am Lech, Bayern"/>
        </Helmet>
        <Header/>

        <main className={'main main--impressum'}>
          <section className="section section--impressum">
            <div className="section__content">

              <h3 class="heading heading--section">
                Datenschutz
              </h3>

              <p className={'section__paragraph'}>
                <h3 className="heading heading--small">
                  Datenschutzbeauftragte:
                </h3>
                Karin Eideloth<br/>
                Herzog-Wilhelm-Str. 25 <br/>
                80331 München
              </p>

              <p class={'section__paragraph'}>
                <h3 className="heading heading--small">
                  Server-Log-Dateien
                </h3>
                Der Provider der Seiten erhebt und speichert automatisch Informationen in so genannten
                Server-Log-Dateien, die Ihr Browser automatisch an uns übermittelt. Dies sind:
                <br/><br/>
                Browsertyp und Browserversion, verwendetes Betriebssystem,
                Referrer URL,
                Hostname des zugreifenden Rechners,
                Uhrzeit der Serveranfrage,
                IP-Adresse<br/>
                <br/>

                Eine Zusammenführung dieser Daten mit anderen Datenquellen wird nicht vorgenommen.
                <br/>
                Die Erfassung dieser Daten erfolgt auf Grundlage von Art. 6 Abs. 1 lit. f DSGVO. Der
                Websitebetreiber hat ein berechtigtes Interesse an der technisch fehlerfreien Darstellung
                und der Optimierung seiner Website – hierzu müssen die Server-Log-Files erfasst werden.
              </p>

              <p className={'section__paragraph'}>
                <h3 className="heading heading--small">
                  Amazon Cloudfront
                </h3>

                Diese Website benutzt das Content Delivery Network (CDN) Cloudfront. Dies ist ein Dienst der
                Amazon Web Services Inc., 410 Terry Avenue North, Seattle, WA 98109-5210. Das Cloudfront CDN
                stellt Duplikate von Daten einer Website auf verschiedenen weltweit verteilten Amazon Web
                Services (AWS) Servern zur Verfügung. Dadurch wird eine schnellere Ladezeit der Website,
                eine höhere Ausfallsicherheit und ein erhöhter Schutz vor Datenverlust erreicht. Einige der
                auf dieser Website eingebundenen Bilder und Videos werden beim Aufruf der Seite vom
                Cloudfront CDN bezogen. Durch diesen Abruf werden Informationen über Ihre Nutzung unserer
                Website (wie z.B. Ihre IP-Adresse) an Server von Amazon ins EU-Ausland übertragen und dort
                gespeichert. Dies geschieht sobald sie unsere Website betreten. Die Nutzung von Amazon Web
                Services und dem Amazon CDN Cloudfront erfolgt im Interesse einer höheren Ausfallsicherheit
                der Website, dem erhöhten Schutz vor Datenverlust und einer besseren Ladegeschwindigkeit
                dieser Website. Dies stellt ein berechtigtes Interesse im Sinne von Art. 6 Abs. 1 lit. f
                DSGVO dar. Mehr über die Datenschutz-Maßnahmen von Amazon Web Services erfahren Sie
                unter: <br/>https://aws.amazon.com/de/data-protection/
                <br/><br/>
                Die aktuelle Datenschutzerklärung von Amazon Web Services finden Sie unter: <br/>https://aws.amazon.com/de/privacy/
                <br/><br/>
                Amazon Web Services Inc. mit Sitz in den USA ist für das us-europäische
                Datenschutzübereinkommen „Privacy Shield“ zertifiziert, welches die Einhaltung des in der EU
                geltenden Datenschutzniveaus gewährleistet.

              </p>

            </div>
          </section>

        </main>
        <Footer/>
      </div>
  );
};

export default DatenschutzPage;
